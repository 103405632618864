import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { , faCoffee } from '@fortawesome/fontawesome-free-solid'
import { faMobileNotch } from '@fortawesome/free-solid-svg-icons'


const BASE_URL = "https://ritswa-consultancy-static-assets.s3.ap-south-1.amazonaws.com/services.json";
const data = {
    "heading": "The one-stop solution for all your needs",
    "headingText_1": "We provide a wide range of services starting from software developement, UI/UX  to cloud migration a very affordable price",
    "headingText_2": "We provide a wide range of digital services starting from software developement to marketing at a very affordable price",
    "serviceData": [
      {
        "id": 1,
        "iconClass": "fa-lg fa-mobile",
        "designClass": "color-4 icon-bg-4",
        "title": "App Development",
        "text": "We develop the best in class mobile app for both android and IOS that stands out from the rest.We have expertise in choosing what the best solution is for your mobile app development strategy.",
        "btnText": "Learn More"
      },
      {
        "id": 2,
        "iconClass": "fa-cloud",
        "designClass": "color-3 icon-bg-3",
        "title": "Cloud Migration",
        "text": "Our Cloud Migration Services focus on providing comprehensive offerings to help you to define and execute your cloud strategy with a structure based approach for a full-stack migration that includes applications, infrastructure, and DevOps Automation.",
        "btnText": "Learn More"
      },
      {
        "id": 3,
        "iconClass": "fa-desktop",
        "designClass":"color-2 icon-bg-2",
        "title": "Web Development",
        "text": "Whether you are looking to develop a small or a large web application, we have got you covered with our web development team that creates secure and scalable web solutions tailored exclusively for your business goals.",
        "btnText": "Learn More"
      },

      // {
      //   "id": 4,
      //   "iconClass": "fa-rocket",
      //   "designClass":"color-1 icon-bg-1",
      //   "title": "Digital Marketing",
      //   "text": "We provide everything you need to get the attention of your audience, with our dazzling strategies, you can have an exceptional advantage. We facilitate your business by running ads on various social media platforms. We are the business creating agency",
      //   "btnText": "Learn More"
      // },
      // {
      //   "id": 5,
      //   "iconClass": "fa-envelope-open-text",
      //   "designClass":"color-5 icon-bg-5",
      //   "title": "Email Marketing",
      //   "text": "We help you to engage with fascinating people, through our dynamic email services. We provide you, with access to thousands of people, who could be your next potential customers. Let's together build a lucrative customer base for your business.",
      //   "btnText": "Learn More"
      // },
      {
        "id": 6,
        "iconClass": "fa-eye",
        "designClass":"color-6 icon-bg-6",
        "title": "UI/UX Designing",
        "text": "We provide you with world class UI/UX designs, which fascinates the eyes of your audience and can effortlessly transfigure them into your customers. With our creative and exhilarative designs, for your website and app, you can straight - forwardly stand out from the crowd",
        "btnText": "Learn More"
      },
      // {
      //   "id": 7,
      //   "iconClass": "fa-play",
      //   "designClass":"color-2 icon-bg-2",
      //   "title": "Graphic Designing",
      //   "text": "We believe great quality design can revitalize your business journey, that's why at Ritswa our designers furnish you with premium quality and creative graphic designing, which is highly charismatic and eye pleasing.",
      //   "btnText": "Learn More"
      // },
      // {
      //   "id": 8,
      //   "iconClass": "fa-outdent",
      //   "designClass":"color-1 icon-bg-1",
      //   "title": "Content Writing",
      //   "text": "Our writers are carefully evaluated and experienced, we focus on the quality and authenticity of the content, and with the creative ideas of our writers, it would be a cherry on the cake for grabbing the attention of the audience.",
      //   "btnText": "Learn More"
      // },
      // {
      //   "id": 9,
      //   "iconClass": "fa-keyboard",
      //   "designClass":"color-4 icon-bg-4",
      //   "title": "Data Entry",
      //   "text": "Our professionals facilitate you with comprehensive accuracy in data entry, and have the potential to enter data at a mass level in a nominal time frame, and also ensures the privacy of your data.",
      //   "btnText": "Learn More"
      // }
    ]
  }
class ServiceOne extends Component {
    state = {
        data: data,
        serviceData: data.serviceData
    }
    render() {
        return (
            <section id="service" className="section service-area  ptb_150">
                {/* Shape Top */}
                {/* <div className="shape shape-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div> */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.serviceData.map((item, idx) => {
                            return(
                                <div key={idx} className="col-12 col-md-6 col-lg-6 mb-5">
                                    {/* Single Service */}
                                    <div className="single-service p-4 text-center">
                                        {/* <span className={item.iconClass} /> */}
                                        <span className={item.designClass}><FontAwesomeIcon icon={item.iconClass}  size="lg"/></span>
                                        <h3 className="my-3">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* Shape Bottom */}
                {/* <div className="shape shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div> */}
            </section>
        );
    }
}

export default ServiceOne;