import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOnePricingSection";

const data = {
    "heading": "Our Price Plans",
    "headingText_1": "We provide you the foremost services at an ultra - cheap price, starting just from INR 600. So what are you waiting for, grow your business with us , at just a nominal price.",
    "headingText_2": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    "btnText": "Not sure what to choose?",
    "btnText_1": "Contact Us",
    "pricingData": [
      {
        "id": 1,
        "colClass": "col-12 col-md-6",
        "linkClass": "single-price-plan color-1 bg-hover hover-top text-center p-5",
        "linkClass_1": "single-price-plan color-1 bg-hover hover-top dark-bg dark-shadow text-center p-5",
        "price_badge": "",
        "price_badge_1": "",
        "title": "Basic",
        "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, nemo.",
        "price_before": "$",
        "price": "49",
        "price_after": "/mo",
        "li_1": "5GB Linux Web Space",
        "li_2": "5 MySQL Databases",
        "li_3": "24/7 Tech Support",
        "li_4": "Daily Backups",
        "listClass_1": "py-2",
        "listClass_2": "py-2 text-muted",
        "listClass_3": "text-white py-2",
        "listClass_4": "py-2 text-muted",
        "btnClass": "btn btn-bordered mt-3",
        "btnClass_1": "btn btn-bordered-white mt-3",
        "btnText": "Get Started"
      },
      {
        "id": 2,
        "colClass": "col-12 col-md-6 mt-4 mt-md-0",
        "linkClass": "single-price-plan color-2 bg-hover active hover-top text-center p-5",
        "linkClass_1": "single-price-plan color-2 bg-hover active hover-top dark-bg dark-shadow text-center p-5",
        "price_badge": "Save 20%",
        "price_badge_1": "Recommended",
        "title": "Pro",
        "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, nemo.",
        "price_before": "$",
        "price": "129",
        "price_after": "/mo",
        "li_1": "10GB Linux Web Space",
        "li_2": "50 MySQL Databases",
        "li_3": "Unlimited Email",
        "li_4": "Daily Backups",
        "listClass_1": "py-2",
        "listClass_2": "py-2",
        "listClass_3": "text-white py-2",
        "listClass_4": "text-white py-2",
        "btnClass": "btn btn-bordered active mt-3",
        "btnClass_1": "btn btn-bordered-white active mt-3",
        "btnText": "Get Started"
      }
    ]
  }
class PricingOne extends Component {
    state = {
        data: data,
        pricingData: data.pricingData
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 pricingData: res.data.pricingData
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <section id="pricing" className="section price-plan-area  overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="row price-plan-wrapper">
                                {this.state.pricingData.map((item, idx) => {
                                    return(
                                        <div key={`pro_${idx}`} className={item.colClass}>
                                            {/* Single Price Plan */}
                                            <div className={item.linkClass}>
                                                {/* Plan Title */}
                                                <div className="plan-title mb-2 mb-sm-3">
                                                    <h3 className="mb-2">{item.title} <sup><span className="badge badge-pill badge-warning ml-2">{item.price_badge}</span></sup></h3>
                                                    <p>{item.text}</p>
                                                </div>
                                                {/* Plan Price */}
                                                <div className="plan-price pb-2 pb-sm-3">
                                                    <span className="color-primary fw-7">{item.price_before}</span>
                                                    <span className="h1 fw-7">{item.price}</span>
                                                    <sub className="validity text-muted fw-5">{item.price_after}</sub>
                                                </div>
                                                {/* Plan Description */}
                                                <div className="plan-description">
                                                    <ul className="plan-features">
                                                        <li className={item.listClass_1}>{item.li_1}</li>
                                                        <li className={item.listClass_1}>{item.li_2}</li>
                                                        <li className={item.listClass_2}>{item.li_3}</li>
                                                        <li className={item.listClass_2}>{item.li_4}</li>
                                                    </ul>
                                                </div>
                                                {/* Plan Button */}
                                                <div className="plan-button">
                                                    <a href="/contact" className="btn btn-bordered mt-3">{item.btnText}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-5">
                        <p className="pt-4 fw-5">{this.state.data.btnText} <a className="service-btn" href="/contact"><strong>{this.state.data.btnText_1}</strong></a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingOne;