import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";
const data = {
    "footerTitle_1": "About Us",
    "footerTitle_2": "Services",
    "footerTitle_3": "Support",
    "footerTitle_4": "Follow Us",
    "about": "We help clients achieve their business goals with our services that drive revenue growth. From strategy to execution and execution to optimization, our team strives to give maximum ROI to our clients. We offer various services such as software development, digital marketing, email marketing, UI/UX designing, etc.",
    "follow":"To stay updated with our current services and offers, you can follow us on our social media handles",
    "footerList_1": [
      {
        "id": 1,
        "text": "Company Profile"
      },
      {
        "id": 2,
        "text": "Testimonials"
      },
      {
        "id": 3,
        "text": "Careers"
      },
      {
        "id": 4,
        "text": "Partners"
      },
      {
        "id": 5,
        "text": "Affiliate Program"
      }
    ],
    "footerList_2": [
      {
        "id": 1,
        "text": "Web Application"
      },
      {
        "id": 2,
        "text": "Product Management"
      },
      {
        "id": 3,
        "text": "User Interaction Design"
      },
      {
        "id": 4,
        "text": "UX Consultant"
      },
      {
        "id": 5,
        "text": "Social Media Marketing"
      }
    ],
    "footerList_3": [
      {
        "id": 1,
        "text": "Frequently Asked"
      },
      {
        "id": 2,
        "text": "Terms & Conditions"
      },
      {
        "id": 3,
        "text": "Privacy Policy"
      },
      {
        "id": 4,
        "text": "Help Center"
      },
      {
        "id": 5,
        "text": "Contact Us"
      }
    ],
    "iconList": [
      {
        "id": 1,
        "iconClass": "fab fa-facebook",
        "url": "https://facebook.com/ritswa"
      },
      {
        "id": 2,
        "iconClass": "fab fa-twitter",
        "url": ""
      },
      {
        "id": 3,
        "iconClass": "fab fa-telegram",
        "url": "https://t.me/ritswaconsultancy"
      },
      {
        "id": 4,
        "iconClass": "fab fa-linkedin-in",
        "url": ""
      },
      {
        "id": 5,
        "iconClass": "fab fa-instagram",
        "url": "https://instagram.com/ritswa"
      }
    ]
  }
class FooterOne extends Component {
    state = {
        data: data,
        footerList_1: data.footerList_1,
        footerList_2: data.footerList_2,
        footerList_3: data.footerList_3,
        iconList: data.iconList
    }
    render() {
        return (
            <footer className="section footer-area">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-uppercase mb-2">{this.state.data.footerTitle_1}</h3>
                                    {/* <ul>
                                        {this.state.footerList_1.map((item, idx) => {
                                            return(
                                                <li key={`flo_${idx}`} className="py-2"><a className="text-black-50" href="/about">{item.text}</a></li>
                                            );
                                        })}
                                    </ul> */}
                                     <p className="mb-2 text-black-50 text-justify">{this.state.data.about}</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items ml-5">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-uppercase mb-2">{this.state.data.footerTitle_2}</h3>
                                    <ul>
                                        {this.state.footerList_2.map((item, idx) => {
                                            return(
                                                <li key={`flt_${idx}`} className="py-2"><a className="text-black-50" >{item.text}</a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items ml-5">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-uppercase mb-2">{this.state.data.footerTitle_3}</h3>
                                    <ul>
                                        {this.state.footerList_3.map((item, idx) => {
                                            return(
                                                <li key={`flth_${idx}`} className="py-2"><a className="text-black-50"   >{item.text}</a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            <div className="footer-items ml-2">
                                    {/* Footer Title */}
                                    <h3 className="footer-title text-uppercase mb-2">{this.state.data.footerTitle_4}</h3>
                                    <p className="mb-2">{this.state.data.follow}</p>
                                    {/* Social Icons */}
                                    <ul className="social-icons list-inline pt-2">
                                        {this.state.iconList.map((item, idx) => {
                                            return(
                                                <li key={`fi_${idx}`} className="list-inline-item px-1"><a href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.iconClass} /></a></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">© Copyrights 2022 Ritswa All rights reserved.</div>
                                    {/* Copyright Right */}
                                    {/* <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">Themeland</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterOne;