import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes

import ThemeSeven from "../themes/theme-seven";

import Services from "../components/InnerPages/Services/Services";

import Contact from "../components/InnerPages/Contact/Contact";
import Error from "../components/InnerPages/Error/Error";


class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeSeven} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/404" component={Error} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;