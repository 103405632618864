import React, { Component } from 'react';

const initData = {
    heading: "Our clients says",
    headingText_1: "Some inspiration from our clients, who bring out the best of our work.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        id: 1,
        quote: "/img/quote.png",
        avatar: "/img/james.jpeg",
        reviewer: "James Willey",
        post: "PMO, Sprinter",
        text: "The Ritswa consultancy team has become an remarkable part of Sprinter , and we would like to appreciate their efforts and passion for the product. The team is flexible and willing to try various technologies and frameworks to find the best possible solution to the problem at hand. As a result, Sentrons XP has evolved into an easy-to-use and scalable platform. Thus they are crucial for our success."
    },
    {
        id: 2,
        quote: "/img/quote.png",
        avatar: "/img/stuart.jpeg",
        reviewer: "Yasmin Akter",
        post: "Product Lead,Shirishanu",
        text: "Team members proved to be extremely flexible and responsive. They stayed in daily contact with us, which allowed us to adjust the scope of works promptly and implement new requirements on the fly. Additionally, the team delivered demos every other week so that we could be sure that the invoicing system aligned with our business needs."
    },
    {
        id: 3,
        quote: "/img/quote.png",
        avatar: "/img/avatar_2.png",
        reviewer: "Surya Pratap",
        post: "Manager, AfroSoft",
        text: "The developers used mindful approach to ensure the system's scalability, which is critical for us as our user base is growing. They selected and implemented techs that ensured the system's availability and fault-tolerance in the future. We were particularly pleased with neat alignment of the developed module with our requirements."
    }
]

class ReviewOne extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="section review-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.initData.heading}</h2>
                                <p className=" d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className=" d-block d-sm-none mt-4">{this.state.initData.headingText_1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Client Reviews */}
                        <div className="client-reviews owl-carousel">
                            {/* Single Review */}
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`ro_${idx}`} className="single-review p-5">
                                        {/* Review Content */}
                                        <div className="review-content">
                                            {/* Review Text */}
                                            <div className="review-text">
                                                <p>{item.text}</p>
                                            </div>
                                            {/* Quotation Icon */}
                                            <div className="quot-icon">
                                                <img className="avatar-sm" src={item.quote} alt="" />
                                            </div>
                                        </div>
                                        {/* Reviewer */}
                                        <div className="reviewer media mt-3">
                                            {/* Reviewer Thumb */}
                                            <div className="reviewer-thumb">
                                                <img className="avatar-lg radius-100" src={item.avatar} alt="" />
                                            </div>
                                            {/* Reviewer Media */}
                                            <div className="reviewer-meta media-body align-self-center ml-4">
                                                <h5 className="reviewer-name color-primary mb-2">{item.reviewer}</h5>
                                                <h6 className="text-secondary fw-6">{item.post}</h6>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewOne;